import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import {
  enrichStoryWithGatsbyImages,
  getBlok,
} from "../components/utils/storyblok";
import Seo from "../components/layout/seo";
import {
  getPageSlug,
  prefixUrl,
  suffixTitle,
} from "../components/utils/no-import-commons";

const Page = ({ data, pageContext }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  enrichStoryWithGatsbyImages(
    story,
    data?.images?.edges.map((e) => e?.node) ?? []
  );

  return (
    <Layout languageMappings={pageContext?.lm ?? {}}>
      {(story?.content?.content ?? []).map(getBlok)}
    </Layout>
  );
};

export const Head = ({ data, pageContext }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  const seoInfo = story?.content?.page_information[0];
  seoInfo.url = prefixUrl(getPageSlug(seoInfo.slug, story.lang));
  seoInfo.title = suffixTitle(seoInfo?.title ?? "");
  const lm = {};
  for (const k in pageContext?.lm ?? {}) {
    lm[k] = prefixUrl(getPageSlug(lm[k], k));
  }

  return <Seo info={seoInfo} lm={lm} />;
};

export const query = graphql`
  query ($id: String!, $images: [String]!) {
    storyblokEntry(id: { eq: $id }) {
      field_component
      content
      lang
      name
      slug
    }
    images: allFile(filter: { base: { in: $images } }) {
      edges {
        node {
          base
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default Page;
